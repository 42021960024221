import * as React from "react"
import { Link } from "gatsby"
import Nav2 from "../components/nav2"
import Footer from "../components/footer"

const SuccessPage = () => {
  return (
    <div pageTitle="プライバシーポリシー">
      <Nav2 />
      <main className="nomal-page">
        <h1>プライバシーポリシー</h1>
        <br />
        <section>
          <h2>法令の遵守</h2>
          <p>「ほぐしのエリー」（以下当サイト）は個人情報を取り扱うにあたり、個人情報を保護することは、社会的責務であると考えております。次のとおり「個人情報保護方針」を定め、これを遵守してまいります。</p>
        </section>
        <br />
        <section>
          <h2>利用目的の明示及び目的外使用の禁止</h2>
          <p>当サイトでは、下記の目的で個人情報をご提供いただいております。これ以外の目的でご提供いただく際は、事前にその旨をお伝えした上で実施いたします。本人の同意を得ずに個人情報を目的以外に利用することはいたしません。</p>
          <p>
            ・お問い合わせ<br />
            ・セッションのご予約<br />
            ・上記に纏わる各種ご連絡</p>
        </section>
        <br />
        <section>
          <h2>個人情報の第三者への非開示・非提供</h2>
          <p>提供いただいた個人情報は、下記の場合を除いて第三者に開示、提供いたしません。この場合であっても、開示・提供する情報は必要最小限度のものといたします。</p><p>
            ・ご本人の同意がある場合<br />
            ・法的根拠に基づき官公庁などから請求された場合
          </p>
        </section>
        <br />
        <p>
          <Link to="/" className="underline">ホームに戻る</Link>.<br/>
        </p>
      </main>
      <Footer />
    </div>
  )
}

export default SuccessPage
